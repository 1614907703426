<template>
  <div>
    <!--    hallo-->
    <!--    <pre class="displayNone">-->
    <!--      &lt;!&ndash;      Braucht es da sonst ein bug bezüglich der anzeige entsteht wenn sie nicht zufor aufgerufen werden&ndash;&gt;-->
    <!--      {{ collection }}-->
    <!--      {{ user }}-->
    <!--    </pre>-->
    <div class="home" v-if="collection">

      <div class="avatarContainer">
        <img class="userAvatar" :src="identicon" alt="">
        <h1 class="title">{{ user.name }}</h1>
      </div>


      <div class="content">
        <h2>Biographie of {{ user.name }}</h2>
        <p>{{ user.bio }}</p>
        <!--        <br>-->
        <!--        <h2>Public Address</h2>-->
        <!--        <input type="text" :value="user.publicAddress" disabled>-->

        <br>

        <div class="switch-field" style="max-width: 600px">
          <input type="radio" id="radio-one" name="switch-one" value="yes" checked/>
          <label @click="toggle.tab = 1" for="radio-one">Collections </label>
          <input type="radio" id="radio-two" name="switch-one" value="no"/>
          <label @click="toggle.tab = 2" for="radio-two">NFTs</label>
          <input type="radio" id="radio-tree" name="switch-one" value="no"/>
          <label @click="toggle.tab = 3" for="radio-tree">Listings</label>
        </div>
        <br>
        <div class="container" v-if="tabThree">
          <div :key="listing.id" v-for="listing in listings" class="nft_listing">
            <ListingOverview v-if="listing" :inputListing="listing"
                             :key="listing.id"/>
          </div>
        </div>

        <div class="container" v-if="tabTwo">
          <div :key="nft.id" v-for="nft in nfts" class="nft_listing">
            <nft-overview v-if="nft" :inputNft="nft" :key="nft.tokenId"></nft-overview>
          </div>
        </div>

        <div class="container" v-if="tabOne ">
          <div v-for="collection in collections" :key="collection.id" class="nft_listing">
            <collectionOverview :data="collection"/>
          </div>
        </div>
      </div>
      <br>
      <br>


      <br>
      <br> <br>
      <br> <br>
      <br>

      <!--    <img alt="Vue logo" src="../assets/logo.png">-->
      <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import NftOverview from "../components/nft/nftOverview";
import Api from "../../services/Api";
import NftOverview from "../../components/nft/nftOverview";
import collectionOverview from "../../components/nft/collectionOverview";
import ListingOverview from "../../components/nft/listingOverview";
import ChainApi from "../../services/ChainApi";
// import IpfsAPI from "../../services/IpfsAPI";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'userSingle',
  components: {
    ListingOverview,
    NftOverview,
    collectionOverview,
  },
  computed: {
    tabOne() {
      return this.toggle.tab === 1;
    },
    tabTwo() {
      return this.toggle.tab === 2;
    },
    tabThree() {
      return this.toggle.tab === 3;
    }
  },
  data() {
    return {
      user_id: null,
      collection: {},
      user: {},
      identicon: null,
      toggle: {
        tab: 0,
      },

      collections: [],
      listings: [],
      nfts: []
    }
  },
  mounted() {
    this.init()
  },

  activated() {
    this.init()
  },
  watch: {
    $route(to, from) {
      console.log(to, from)
      this.$nextTick(() => {
        this.init()
      })
    }
  },

  beforeRouteLeave(name, from, next) {
    this.collection = {}
    this.collectios = []
    this.nfts = []
    this.listings = []
    this.user = {}
    next();
  },

  methods: {
    async init() {
      this.$nextTick(async () => {
        this.user_id = this.$route.params.id
        await this.getUser(this.user_id);
        await this.getIdenticon(this.user.publicAddress);
        await this.getCollections(this.user_id);
        await this.getListings(this.user_id);
        await this.getNFTs(this.user_id);
        // await this.getListing(this.user_id);
      })
    },

    async getIdenticon(publicAddress) {
      this.identicon = await ChainApi.getIdenticon(publicAddress);
    },
    async getUser(id) {
      this.user = null
      const response = await axios.get(Api.baseUrl + "/users/" + id)
      this.user = response.data[0]
    },


    async getCollections(id) {
      const response = await axios.get(Api.baseUrl + "/collections/byUserId/" + id)
      this.collections = response.data
      console.log(response)
    },
    async getNFTs(id) {
      const response = await axios.get(Api.baseUrl + "/nftsByUser/" + id)
      this.nfts = response.data
    },

    async getListings(id) {

      const response = await axios.get(Api.baseUrl + "/listingsByUser/" + id)
      // console.log(response.data);
      // this.listings = response.data

      let listingsModel = response.data

      for (let listing of listingsModel) {
        if (listing.nft_id) {
          const response2 = await axios.get(Api.baseUrl + "/nfts/" + listing.nft_id)
          listing.nft = response2.data[0]
          // console.log(response2)
        }
      }

      this.toggle.tab = 1

      this.listings = listingsModel

    }


  },
}
</script>
 <style scoped lang="scss">
@import 'src/styles/style.scss';



.displayNone {
  display: none;
}

.nftImageWrapper {
  width: 100%;
  background-size: cover;
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: calc(100%);
  background: #f5f5f7;

  //display: flex;
  //justify-content: center;
  //align-items: center;
  border-radius: 12px;
  margin-bottom: 16px;
}

.nftImage {
  width: auto;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  //padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;


  img {
    //max-width: 200px;
    //max-height: 200px;
    border-radius: 12px !important;
  }
}


.nftMedia {

  background: transparent;
  padding: 0;
  margin-bottom: 16px;

  img {
    width: 100%;
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;
}



.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
